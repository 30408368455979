.table-input-box {
    height: 30px;
    margin: 0 !important;
    border-radius: 0 !important;
    border: none !important;
}

.table-input-box:focus {
    border: none !important;
    border-radius: 0 !important;
    /* box-shadow: none !important; */
}

.table-input-box::placeholder {
    color: #dc3545 !important;
    font-size: 15px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.table-box {
    padding: .3rem;
}

.navigate-section-header {
    background: rgb(255, 255, 255);
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
    height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrow-icon {
    margin-bottom: -38px !important;
}

.arrow-icon svg {
    width: 1.8em !important;
    height: 1.8em !important;
}

.arrow-box-active {
    border-bottom: 3px solid #1976d2;
    top: 45px;
    margin-bottom: -19px;
}

.table_title_top {
    display: flex;
    align-items: center;
    gap: 5rem;
    margin-bottom: 1rem;
}

.table_title_top p {
    margin-bottom: 0;
}

.table_title_top .drafted_text {
    font-size: 18px;
    color: #dc3545;
    font-weight: 500;
}

.table_title_top .drafted_text svg {
    font-size: 18px;
    color: #dc3545;
    margin-top: -3px;
}

.table_title_top .submit_text {
    font-size: 18px;
    color: #2bc63a;
    font-weight: 500;
}
.submit_text1s{
    font-size: 18px;
    color: #2bc63a;
    font-weight: 500;
}

.table_title_top .submit_text img {
    margin-top: -3px;
}

.date_input {
    border: none;
    outline: none;
    width: 112px;
}

.sr_no_input {
    outline: none;
    /* border-bottom: 1px solid #aaaaaa; */
    margin-left: .5rem;
    padding-left: 5px;
    padding-right: 0px;
}

