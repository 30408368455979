/* pdf page css */

s.pdf_page {
    font-family: "Noto Serif Devanagari";
    flex-direction: row;
    background-color: #E4E4E4;
}

.pdf_section {
    margin: 10px;
    padding: 10px;
    flex-grow: 1;
}

.pdf_table {
    display: table;
    width: auto;
    border-style: solid;
    border-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    font-family: "Noto Serif Devanagari";
}

.pdf_tableRow {
    flex-direction: row;
    font-family: "Noto Serif Devanagari";
}

.pdf_tableCell {
    margin: auto;
    margin-top: 5px;
    font-size: 10px;
    border-style: solid;
    border-width: 1px;
    border-left-width: 0px;
    border-top-width: 0px;
    padding: 5px;
    font-family: "Noto Serif Devanagari";
}
/* pdf page css */

/* the new generate css */
.next_btn{
    background-color: #49ab4e;
    color: white;
    border: 1px solid #49ab4e;
    padding: 3px 11px;
    width: 85px;
    border-radius: 3px;
}
.btn_position{
    display: flex;
    justify-content: end;
    margin-right: 12px;
    gap: 8px;
}
.previous_btn{
    background-color: #49ab4e;
    color: white;
    border: 1px solid #49ab4e;
    padding: 3px 10px;
    border-radius: 3px;
}
.btn_generate{
    background-color: #2196F3;
    color: white;
    border: 1px solid #2196F3;
    padding: 3px 10px;
    border-radius: 3px;
}