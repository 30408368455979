/* .pdf_viewer_css{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 700px; 
    overflow-y: auto; 
    padding: 16px; 
} */
.btn_prview{
    background-color: #80808000 !important;
    border-color: #d9d9d9 !important;
    color: black;
    /* border-radius: 0px; */
    font-weight: 400 !important;
    font-size: 15px !important;
    font-family: Poppins;
}
.downloadPdf{
    font-weight: 400 !important;
    font-size: 15px !important;
    font-family: Poppins;
}