@media (max-width:1000px) {
    .main-section {
        flex-direction: column;
    }
}

@media (max-width:600px) {
    .main-login-card {
        width: 22rem !important;
    }
}