/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.patrak_heading {
    border: 1px solid black;
    font-size: 15px;
    border-width: 2px;
    padding: 3px 9px;
    font-weight: bold;
    font-size: 18px;
}

.text_font th {
    font-size: 13px;
    padding: 1px;
}

.text_font td {
    font-size: 13px;
    font-weight: 700;
    text-align: center;
}

.text_font_padding td {
    font-size: 11px;
    font-weight: 700;
    padding: 11px !important;
    border-bottom: 2px dashed black !important;
}

.text_font_padding_top td {
    font-size: 11px;
    font-weight: 700;
    padding: 11px !important;
    border-top: 2px dashed black !important;
}

.grmapnchyat_start {
    position: relative;
    left: 20%;
}

.rotate_column_text1 {
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
    /* Vertical writing mode */
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    /* Rotate 180 degrees to flip it upright */
    white-space: nowrap;
}

.margin_class {
    margin-top: 50% !important;
}

.form_btn_space{
    display: flex;
    justify-content: end;
    margin-bottom: 0px !important;
    margin-top: 2rem;
    position: relative;
    gap: 9px;
}
.form_btn_space_e{
    display: flex;
    justify-content: end;
    margin-bottom: 0px !important;
    margin-top: 1rem;
}

.btn_position{
    display: flex;
    gap: 8px;
    /* margin-left: 8px; */
}
/* sample pages css rohit */
.patrak_title_top{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 33px;
}
.sample_sec_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sample_sec_row {
    width: 80% !important;
}

.sample_page_section_main {
    margin: 2rem 0;
}

.sample_page_title_top p {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
}

.sample_page_title_top span {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
}

.dtv_info_sec_main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.dtv_info_sec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 58%;
}

.dtv_info_sub_sec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1.6rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.dtv_info_sub_sec p {
    font-size: 18px;
    margin-bottom: 0px !important;
}

.dtv_info_sub_sec span {
    font-size: 18px;
    font-weight: 600;
}

.custom_tr_second td {
    padding: 15px !important;
}

.sarpanch_sign {
    position: relative;
    left: 35%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
}


/* rohit css */

.custom_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 2rem 0rem;
}

.custom_row {
    width: 90% !important;
}

.patrak_title_top p {
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    padding-top: 1rem !important;
}

.patrak_title_top p span {
    font-size: 20px;
    font-weight: 600;
    border: 1px solid #212121;
    padding: 0.3rem;
    margin-left: 0.7rem;
}

.custom_thead tr th {
    text-align: center;
    font-size: 13px;
}

.custom_tr td {
    text-align: center;
    font-size: 13px;
    font-weight: 700;
}

.custom_tr_second td {
    text-align: center;
    /* padding: 1.3rem 0 !important; */
    font-size: 13px;
}

.custom_blank_tr td {
    text-align: center;
    padding: 1rem 0 !important;
    font-size: 13px;
}

.rotate_col {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    padding: 1.5rem 0;
    font-size: 13px;
}
.sample_pages_common_tr td {
    /* font-size: 14px; */
    padding: .1rem !important;
}

.sample_pages_common_tr td:first-child {
    text-align: center;
}

.sample_pages_common_tr td:nth-child(5) {
    text-align: center;
}

/* sample pages css rohit */

.sample_sec_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sample_sec_row {
    width: 80% !important;
    /* border: 1px solid black; */
}

.sample_page_section_main {
    margin: 2rem 0;
}

.sample_page_title_top p {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
}

.sample_page_title_top span {
    font-size: 18px;
    text-align: center;
    font-weight: 500;
}

.dtv_info_sec_main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: .8rem;
}

.dtv_info_sec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 65%;
}

.dtv_info_sub_sec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1.6rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.dtv_info_sub_sec p {
    font-size: 18px;
    margin-bottom: 0px !important;
}

.dtv_info_sub_sec span {
    font-size: 18px;
    font-weight: 600;
}

.people_count_price_sec_bottom {
    -webkit-transform: translate(15px, 40px);
    -ms-transform: translate(15px, 40px);
    transform: translate(15px, 40px);
}

.date_bottom_sec p {
    font-weight: 600;
    margin: .5rem 1rem;
}

.date_bottom_sec p span {
    font-weight: 600;
    margin: 0rem .5rem;
}

.sign_sec_main_bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: end;
    width: 90%;
}

.sign_sec_b {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 50%;
    margin: 2rem 0;
}

.sign_sec_b p {
    font-weight: 600;
}

.sample_pages_common_tr td {
    /* font-size: 14px; */
    padding: .1rem !important;
}

.sample_pages_common_tr td:first-child {
    text-align: center;
}

.sample_pages_common_tr td:nth-child(5) {
    text-align: center;
}


/* .table_bordered tr,
th,
td {
    border: 2px solid black !important;
    font-size: 13px;
    border-collapse: collapse;
    font-weight: 700;
} */

.table_bordered .common_tr_border,
.common_tr_border th,
.common_tr_border td {
    border: 2px solid black !important;
    font-size: 13px;
    border-collapse: collapse;
    font-weight: 700;
    padding: 3px;
}

/* sample pages css rohit */

@media (min-width:1300px) and (max-width:1450px) {
    .custom_thead tr,
    th,
    .custom_tr td {
        font-size: 13px;
        font-weight: 700;
    }
    .patrak_title_top p {
        font-size: 16px;
    }
    .patrak_title_top p span {
        font-size: 16px;
    }
}


/* 
@media (min-width:700px) and (max-width:1300px) {
    .sample_sec_row {
        width: 85% !important;
    }
    .sample_pages_common_tr td {
        font-size: 14px;
    }
} */

@media (min-width:600px) and (max-width:1300px) {
    .sample_sec_container {
        max-width: 100% !important;
    }
    .sample_sec_row {
        width: 100% !important;
    }
    .sample_pages_common_tr td {
        font-size: 12px;
    }
}


/* rohit css */

@media (min-width:1300px) and (max-width:1450px) {
    .text_font th {
        font-size: 13px;
        font-weight: 700;
    }
    .text_font td {
        font-size: 11px;
        font-weight: 700;
    }
    .patrak1 {
        overflow-x: scroll;
        width: 100% !important;
        /* margin-bottom: 5rem; */
    }
    .first_table{
        overflow-x: scroll;
        padding-bottom: 50px;
    }
}