.bg-navbar {
    /* background: #1184ed; */
    background: #282828;
}

.navbar-dropdown-profile button {
    background: transparent !important;
    border: none !important;
    font-weight: bold;
    font-family: 'Noto Serif Devanagari', serif;
    font-size: 18px;
    transform: translateY(5px);
}

.grampanchayat_report_title_top {
    font-family: 'Noto Serif Devanagari', serif;
    color: #FFF;
    font-size: 22px;
    margin: 0rem 1rem;
    font-weight: 400;
    text-decoration: none;
    transform: translateY(6px);
}

.welcome_user_text_nav {
    font-family: 'Poppins', sans-serif;
    color: #FFF;
    font-size: 19px;
    margin: 0rem 1rem;
    font-weight: 400;
    text-decoration: none;
    transform: translateY(4px);
    cursor: default;
}

.welcome_user_text_nav span {
    font-family: 'Noto Serif Devanagari', serif;
    color: #FFF;
    font-size: 19px;
    font-weight: 400;
    text-decoration: none;
    cursor: default;
}

.nav-drop-items li:hover {
    background: rgb(238, 238, 238);
}

.profile_dropdown_nav {
    display: flex;
    align-items: center;
}

.burger {
    position: relative;
    width: 40px;
    height: 30px;
    background: transparent;
    cursor: pointer;
    display: block;
}

.burger input {
    display: none;
}

.burger span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: black;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.burger span:nth-of-type(1) {
    top: 0px;
    transform-origin: left center;
}

.burger span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
    transform-origin: left center;
}

.burger span:nth-of-type(3) {
    top: 100%;
    transform-origin: left center;
    transform: translateY(-100%);
}

.burger input:checked~span:nth-of-type(1) {
    transform: rotate(45deg);
    top: 0px;
    left: 5px;
}

.burger input:checked~span:nth-of-type(2) {
    width: 0%;
    opacity: 0;
}

.burger input:checked~span:nth-of-type(3) {
    transform: rotate(-45deg);
    top: 28px;
    left: 5px;
}