.sidebar {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
    padding: 10px;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
    /* Added a transition property */
}

.arrow_btn_group {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.side_bar_hide {
    transform: translateX(-100%);
}

.sidebar::-webkit-scrollbar {
    scrollbar-width: thin I !important;
}

.sidebar .nav-item .nav-link {
    color: #464646;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 2px;
    transition: background-color 0.3s;
    font-family: 'Noto Serif Devanagari', serif;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    position: relative;
}

.sidebar .nav-item .nav-link .text_overflow_span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    position: relative;
}


/* .sidebar .nav-item .nav-link::before {
    content: attr(data-tooltip);
    position: absolute;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
    display: none;
    z-index: 1;
    white-space: normal;
    max-width: none;
}

.sidebar .nav-item .nav-link:hover:before {
    display: block;
} */

.nav-item .form-control:focus {
    border: none !important;
    box-shadow: none !important;
    background-color: #01a6ca;
    color: white;
}

.nav-item .active:focus {
    border: none !important;
    box-shadow: none !important;
    background-color: #01a6ca;
    color: white;
}


/* .sidebar .nav-item .active {
    background-color: #01a6ca;
    color: white;
} */

.sidebar .nav-item {
    background-color: #f5f5f5;
    color: #a6a6a6;
    transition: 1s;
    margin-bottom: 10px;
    width: 100%;
}

.sidebar .sidebar-item:hover {
    background-color: #01a6ca;
    color: white;
}

.sidebar .sidebar_list li:hover {
    background-color: #01a6ca;
    color: white !important;
}

.sidebar .sidebar-item:hover a {
    color: white;
}

.sidebar_list a {
    text-decoration: none;
    color: #7d7d7d;
}

.sidebar-hr {
    /* width: 90%; */
    margin: 0;
}

.sidebar_list {
    list-style: none;
    padding: 0;
}

.sidebar_list li {
    padding-left: 20px;
    font-size: 16px;
    color: #464646 !important;
    font-weight: 600;
    font-family: 'Noto Serif Devanagari', serif;
}

.sidebar_list li:hover a {
    color: white;
}

@media (min-width:1300px) and (max-width:1500px) {
    .sidebar .nav-item .nav-link {
        font-size: 13px;
        /* max-width: 206px; */
    }
    .sidebar_list li {
        font-size: 13px;
    }
}

@media (max-width: 767px) {
    .sidebar {
        max-width: 100%;
    }
}