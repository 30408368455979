:root {
    --blue: #3498db;
    --green: #2ecc71;
    --red: #e74c3c;
    --text-color: #212121;
    --text-color-white: #ffff;
    --background-color: #ffff;
}


/* navbar css */

.sidebar_link_btn svg {
    color: var(--text-color-white);
    font-size: 24px;
}

.VillagePanchayat span {
    color: #212121;
    font-size: 17px;
    margin: 0px 5px;
    font-weight: 500;
}