.table_delete_btn {
    background-color: transparent;
    border: none;
    outline: none;
    text-align: center;
    width: 30%;
}

.table_delete_btn svg {
    color: red;
    font-size: 20px;
}