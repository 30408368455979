.home_navbar_sec {
    background: rgb(255, 255, 255);
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
    height: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.upload_excel_row {
    margin: 4rem 0rem !important;
}

.download_btn_sample_sec {
    display: flex;
    justify-content: end;
    align-items: center;
}

.download_btn_sample_sec .download_btn_sample {
    /* background: linear-gradient(323deg, rgba(2, 0, 36, 1) 0%, rgba(67, 181, 88, 0.8827906162464986) 45%); */
    background: #212121 !important;
    color: #fff !important;
    border: none !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    font-family: 'Poppins', sans-serif !important;
}

.home_file_form {
    display: flex;
    align-items: center;
    gap: 3rem;
}

.upload_home_file_btn {
    /* background: linear-gradient(20deg, rgba(2, 0, 36, 1) 0%, rgba(10, 191, 224, 0.8827906162464986) 54%);
    ;
    color: #fff !important;
    border: none !important;
    font-weight: 600 !important;
    font-size: 16px;
    font-family: 'Poppins', sans-serif !important; */
    background: #0dcaf0 !important;
    color: #fff !important;
    border: none !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    font-family: 'Poppins', sans-serif !important;
    border-radius: 0px !important;
}

.search_input_home {
    position: relative;
}

.upload_home_file_input {
    border: none !important;
    border-bottom: 1px solid #a3a3a34d !important;
    border-radius: 0px !important;
    font-family: 'Poppins', sans-serif !important;
}

.search_icon_home {
    position: absolute;
    right: 5%;
    top: 15%;
}

.upload_message_title {
    color: #212121 !important;
    font-weight: 500;
    font-size: 22px;
    font-family: 'Noto Serif Devanagari', serif;
    margin: 1rem 0rem 0rem 0rem !important;
}

.upload_message_success {
    color: green !important;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Noto Serif Devanagari', serif;
    margin-bottom: 1rem;
}

.upload_message_error {
    color: red !important;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Noto Serif Devanagari', serif;
    margin-bottom: 1rem;
}

.upload_count_main {
    display: flex;
    /* align-items: center; */
    gap: 3rem;
}

.file_upload_count_section {
    display: flex;
    /* align-items: center; */
    gap: 1rem;
}

.upload_count_title {
    color: #212121 !important;
    font-weight: 500;
    font-size: 20px;
    font-family: 'Noto Serif Devanagari', serif;
    /* margin: 1rem 0rem 0rem 0rem !important; */
}

.upload_count_info_success {
    color: green !important;
    font-weight: 500;
    font-size: 20px;
    font-family: 'Noto Serif Devanagari', serif;
    /* margin: 1rem 0rem 0rem 0rem !important; */
}

.upload_count_info_error {
    color: red !important;
    font-weight: 500;
    font-size: 20px;
    font-family: 'Noto Serif Devanagari', serif;
    /* margin: 1rem 0rem 0rem 0rem !important; */
}

.upload_file_img_sec {
    height: 80px;
    width: 80px;
    margin: .3rem;
    text-decoration: none;
}

.upload_file_img_sec img {
    height: 100%;
    width: 100px;
    object-fit: contain;
}

.table_dropdown_with_title {
    display: flex;
    justify-content: space-between;
    width: 50%;
    align-items: center;
    margin: 2rem 0rem;
}

.table_list_title {
    color: #212121 !important;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0px;
    font-family: 'Noto Serif Devanagari', serif;
}

.search_input_main_gharkul {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 2rem;
}

.table_list_input {
    color: #212121 !important;
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #9f9f9f;
    outline: none;
    padding: 0.5rem;
    height: 40px;
}