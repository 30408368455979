.progress-bar-container {
    width: 100%;
    max-width: 100%;
    margin: 20px 0px;
    padding: 0px 10px;
  }
  
  /* .progress-bar {
    height: 55px;
    background-color: #3498db;
    transition: width 0.5s ease-in-out;
    padding: 4% 0px 26px 0px;
  } */
  

  /* step css */
  .stepper-wrapper {
    font-family: Arial;
    /* margin-top: 50px; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  
  .stepper-item::before {
    position: absolute;
    content: "";
    /* border-bottom: 2px solid #ccc; */
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
  }
  .stepper-item{
    cursor: pointer;
  }
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 85%;
    top: 13px;
    left: 57%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #dc3545;
    color: white;
    margin-bottom: 6px;
  }
  
  
  .stepper-item.completed .step-counter {
    background-color: #1e9044;
  }

  .stepper-item.pending_draft .step-counter {
    background-color: #d5b509;
  }

  .stepper-item.gray_bg .step-counter {
    border: 2px solid gray;
    background-color: transparent !important;
  }
  
  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 91%;
    top: 13px;
    left: 50%;
    z-index: 3;
  }

  .stepper-item.pending_draft::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #f6c301;
    width: 91%;
    top: 13px;
    left: 50%;
    z-index: 3;
  }
  .stepper-item.active::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #dc3545;
    width: 91%;
    top: 13px;
    left: 50%;
    z-index: 3;
  }
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
 .step-name{
    font-size: 13px !important;
 }
.step_cross_icon{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}
.filter_text{
  text-decoration: underline;
  font-weight: bold;

}
/* .zoom_circle_name{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.zoom-in-zoom-out {
  width: 6px;
  height: 6px;
  background: #806c00;
  border-radius: 60%;
  animation: zoom-in-zoom-out 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
} */