.dashboard_col_3 {
    width: 16% !important;
}

.dashboard_col_9 {
    width: 84% !important;
}

.navbar_sticky {
    position: sticky;
    top: 0%;
    z-index: 999;
}


/* @media (min-width:1300px) and (max-width:1500px) {
   
} */

@media (max-width:1000px) {
    .dashboard_col_3 {
        width: 100% !important;
    }
    .dashboard_col_9 {
        width: 100% !important;
        padding-left: 0px !important;
    }
}