* {
    padding: 0;
    margin: 0;
}

html,
body,
#root {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
}

.common-section {
    padding-bottom: 120px;
    padding-top: 10px;
}

.table_delete_btn {
    background-color: transparent;
    border: none;
    outline: none;
    text-align: center;
    width: 30%;
}

.table_delete_btn svg {
    color: red;
    font-size: 20px;
}

.taluka_select_input {
    height: 55px;
    width: 185px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    outline: none;
    padding-left: 0.4rem;
}

.total_count_sec {
    padding: 0.3rem 0.8rem 0;
}

.content-wrapper {
    max-width: 1700px;
    margin: 0 auto;
    padding: 20px;
}

.css-1rn30mb-MuiCircularProgress-root {
    color: #337f96 !important;
}

.CircularProgressbar .CircularProgressbar-text {
    font-family: 'Poppins', sans-serif !important;
}

/* custom pop up */
.modal-title {
    color: #282828 !important;
    font-family: 'SHREE-DEV7-0715' !important;
    font-size: 19px !important;
    font-style: normal !important;
    font-weight: 550 !important;
    line-height: normal !important;
}

.modal-content {
    border-radius: 0px !important;
    border: 0px !important;
}

.modal-header {
    border-bottom: 0px !important;
}

.modal-footer {
    border-top: 0px !important;
}
.modal-button-submit{
    background-color: rgb(16, 146, 153) !important;
    color: white !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    border-radius: 0px  !important;
}
.modal-button-cancel{
    color: #464646 !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    border: 1px solid #dbcdcd !important;
    padding: 9px 11px !important;
    border-radius: 0px !important;
}

/* data empty pop up */
.alert_pop_up_common {
    height: 100%;
    width: 60%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 1.5rem 2rem;
}

.alert_pop_up_common_title {
    color: #282828;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    font-family: 'Noto Serif Devanagari', serif;
}

.alert_pop_up_common_des {
    color: rgba(0, 0, 0, 0.80);
    font-size: 19px;
    font-weight: 500;
    line-height: normal;
    font-family: 'Noto Serif Devanagari', serif;
}

.alert_submit_btn {
    background: #109299;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    padding: .8rem 1.5rem 0.5rem 1.5rem;
    margin-top: 50px;
    float: right;
    font-family: 'Noto Serif Devanagari', serif;
}
.sheet_bg_color_pdf{
    padding: 18px;
    background-color: #fafafa;
}
.table_img_height_width{
    height: 80px;
    width: 80px;
    padding-bottom: 8%;
}
.table_img_link{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.table_img_height_width img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.remove_icon_of_draft_image{
    position: relative;
    /* right: 9px; */
    top: 7px;
    display: flex;
    justify-content: end;
}
.common_deputy_row{
    width: 95% !important;
}
.common_deputy_container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.text-overflow{
    display: -webkit-box;
    -webkit-box-orient: horizontal;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-left: 15rem;
}
.common_box{
    box-shadow: 0 0px 49px rgb(125 159 183 / 20%);
    padding: 50px 50px !important;
}
.styled-select{
    -moz-appearance:auto !important; /* Firefox */
    -webkit-appearance:auto !important; /* Safari and Chrome */
    appearance:auto !important;
}
.commont_th{
    width: 1rem !important;
}
.common_work{
    width: 5rem !important;
}
.common_worked{
    width: 6rem !important;
}
@media (min-width:1500px){
    .common_deputy_row{
        width: 85% !important;
    }
}
