/* Loader.css */

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 20vh);
}

.loader-image {
    width: 120px;
    /* animation: spin 1s infinite linear; */
    animation: zoomInOut 2s infinite alternate ease-in-out;
}

.spinner {
    width: 60px;
    height: 60px;
    position: relative;
}

.spinner .dot {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
}

.spinner .dot::after {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: rgb(12, 180, 231);
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.spinner .dot {
    animation: spin 2s infinite;
}

.spinner .dot:nth-child(2) {
    animation-delay: 100ms;
}

.spinner .dot:nth-child(3) {
    animation-delay: 200ms;
}

.spinner .dot:nth-child(4) {
    animation-delay: 300ms;
}

.spinner .dot:nth-child(5) {
    animation-delay: 400ms;
}


/* @keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} */

@keyframes zoomInOut {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}