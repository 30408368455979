.village-input-box {
    border: none;
}

.table-input-box {
    height: 30px;
    margin: 0 !important;
    border-radius: 0 !important;
    border: none !important;
}

.table-input-box:focus {
    border: none !important;
    border-radius: 0 !important;
    /* box-shadow: none !important; */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.top_navigation {
    margin-left: 30vh;
}

.birth_death_label span {
    font-weight: 500;
    font-size: 17px;
    margin-left: 3px;
}