/* Skeleton.css */

.ske_row {
    /* background-color: rgb(240, 240, 240); */
    padding: 0.7rem 0.4rem 0 !important;
    margin-bottom: 1rem !important;
}

.ske_col {
    padding: 0rem .3rem !important;
    margin-bottom: .6rem !important;
}

.ske_col_three {
    display: flex;
    justify-content: space-between;
}

.skeleton_main {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    /* padding-top: 2rem; */
}

.skeleton_popular_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid #ccc; */
    /* margin: 10px;
    padding: 10px; */
    /* width: 14.2%; */
}

.skeleton_image {
    width: 100%;
    height: 175px;
    border-radius: 10px;
    margin: .5rem;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    margin-bottom: .8rem;
}

@keyframes loading {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

@media (min-width:1300px) and (max-width:1550px) {
    .skeleton_image {
        width: 100%;
        height: 135px;
    }
}

@media (max-width:600px) {
    .skeleton_image {
        width: 100%;
        height: 150px;
        border-radius: 0px;
        margin: 0.5rem;
        background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
        background-size: 200% 100%;
        animation: loading 1.5s infinite;
        margin-bottom: 0.8rem;
    }
    .skeleton_popular_card {
        flex-wrap: wrap;
    }
    .ske_col_none {
        display: none !important;
    }
}