.home-box {
    background: rgb(250, 249, 249);
    height: 10vh;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.notice-main {
    border: none !important;
    background: transparent !important;
}

.information-import-main {
    border: none !important;
    background: transparent !important;
}

.incomplete-info-main {
    border: none !important;
    background: transparent !important;
}

.notice-heading {
    font-weight: bold;
}

.notice-body {
    border-radius: 10px !important;
    width: 99%;
    background: #FFF !important;
    box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.25) !important;
    /* height: 25vh; */
}

.information-import-body {
    height: 29vh;
    border-radius: 10px !important;
    background: #FFF !important;
    box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.25) !important;
}

.incomplete-info-body {
    height: 29vh;
    border-radius: 10px !important;
    width: 99%;
    background: #FFF !important;
    box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.25) !important;
}

.notification-alert {
    cursor: pointer;
}

.notification-alert-message {
    background: red;
    text-align: center;
    width: 20px;
    font-size: 12px;
    /* vertical-align: super; */
    /* padding: 2px 7px; */
    border-radius: 100%;
    height: 20px;
    color: white;
    margin-top: -16px;
}

.btn_view {
    /* border: 1px solid #1284ed; */
    background-color: transparent;
    color: #1184ed;
    font-weight: 600;
    text-decoration: underline;
    font-size: 16px;
}

.notice-page-title {
    height: 47px;
}


/* Add this CSS rule to your stylesheet */

.css-i4bv87-MuiSvgIcon-root {
    display: none !important;
}

.custom-dropdown .dropdown-menu {
    width: 400px;
    /* Set a fixed width for the dropdown */
    max-height: 350px;
    /* Set a maximum height for the dropdown */
    overflow-y: auto;
    /* Enable vertical scrolling if content overflows */
}

.custom-dropdown .dropdown-menu a {
    /* Add styles for individual notification items here */
    white-space: wrap;
    /* Prevent text from wrapping to the next line */
    overflow: hidden;
    /* Hide any overflowing content */
    text-overflow: ellipsis;
    /* Show ellipsis for overflowed text */
    display: block;
    padding: 0px 5px;
    text-decoration: none;
    color: #333;
    /* Set the text color */
}

.custom-dropdown .dropdown-menu a :hover {
    color: blue;
    text-decoration: underline;
}


/* Hover effect for notification items */

.custom-dropdown .dropdown-menu a:hover {
    background-color: #f5f5f5;
    /* Set the background color on hover */
}

.custom-dropdown .dropdown-menu.show {
    inset: -35px -6px auto auto !important;
}