.login_main_section {
    height: 87%;
}

.login_left_img_row {
    height: 96% !important;
    overflow-x: scroll;
}

.login_left_img_col {
    padding: 2.5rem 0rem !important;
    background: linear-gradient(90deg, #F6F6F8 51.49%, rgba(246, 246, 248, 0.00) 95.96%);
}

.login_left_img_map_main {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #D9D9D9;
}

.login_left_img_map_main .loginMap {
    height: 500px !important;
}

.login_right_main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.login_right_logo_top {
    margin: 2rem;
    text-align: center;
}

.login_right_logo_top img {
    width: 82%;
}

.main-login-card {
    border: none !important;
    background: rgba(250, 250, 250, 0.70) !important;
    border-top: 0.5px solid #F1F0F0 !important;
    border-bottom: 0.5px solid #F1F0F0 !important;
    width: 75%;
    padding: 1rem;
    position: relative;
}

.page_navigate_top {
    position: absolute;
    top: 5%;
    left: 7%;
}

.page_navigate_top svg {
    font-size: 22px !important;
    color: #282828;
    cursor: pointer;
}

.userLabel {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.text-orange {
    color: orange;
    font-weight: 600;
}

.text-green {
    color: green;
    font-weight: 600;
}

.map-text {
    text-align: left;
}

.main-section {
    margin-top: 5rem !important;
}

.login-text {
    margin-right: 9vw;
    color: #0e6ffd;
    font-weight: 600;
    font-size: 30px;
}

.login_profile_logo_mai {
    text-align: center;
    margin-bottom: 1.5rem;
}


/* .login-card {
    background: #F7F6F4 !important;
    padding: 2rem !important;
    width: 80%;
} */

.login_right_title_top {
    text-align: center;
}

.user-login-text {
    color: #282828;
    font-family: 'Noto Serif Devanagari', serif;
    font-size: 28px;
    font-weight: 600;
    margin: .8rem 0rem 0rem 0rem;
}

.login_right_title_top {
    color: #484848;
    text-align: center;
    font-family: 'Noto Serif Devanagari', serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.login_id_group {
    position: relative;
    margin: 1.5rem 0rem;
}

.login_id_error_group {
    position: relative;
    margin: .5rem 0rem;
}

.login_password_group {
    position: relative;
    margin: 1.5rem 0rem;
}

.login_pass_error_group {
    position: relative;
    margin: .5rem 0rem;
}

.login-input {
    height: 44px;
    width: 100%;
    outline: none !important;
    border-radius: 5px !important;
    border: 1px solid #DEE8EC !important;
    background: #F0F5F7 !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding: 0rem 3rem 0rem 4rem;
}

.login-input::placeholder {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.login_id_icon_sec {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 36%;
    width: 10%;
    border-right: 1px solid #D9D9D9;
}

.login_id_icon_sec .user_id_ico {
    color: #282828;
    font-size: 14px;
}

.login_id_icon_sec .user_pass_ico {
    color: #282828;
    font-size: 16px;
}


/* otp sec css */

.opt_inp_sec {
    position: relative;
    margin: 0.5rem 0rem 1.2rem 0rem;
}

.user_label_sec {
    margin: .5rem 0rem;
}

.otp-input {
    height: 44px;
    width: 100%;
    outline: none !important;
    border-radius: 5px !important;
    border: 1px solid #DEE8EC !important;
    background: #F0F5F7 !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding: 0rem 3rem 0rem 4rem;
}

.otp-input::placeholder {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.login_otp_icon_sec {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 36%;
    width: 10%;
    border-right: 1px solid #D9D9D9;
}

.login_otp_icon_sec .user_otp_ico {
    color: #1C1B1F;
    font-size: 16px;
}

.login_otp_label_sec .otp_verification_title {
    color: #109299;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px !important;
}

.login_otp_label_sec .otp_receive_title {
    font-family: 'Poppins', sans-serif;
    color: rgba(0, 0, 0, 0.60);
    font-size: 14px;
}

.remember_me_sec {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 1rem;
}

.remember_me_sec .remember_text {
    margin-bottom: 0px !important;
    color: #282828;
    font-size: 14px;
    font-weight: 400;
}

.error_sec_login .error_text {
    color: rgb(248, 37, 37);
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.password-text {
    font-weight: 700;
    font-size: 14px;
}

.login-footer {
    position: fixed;
    bottom: 2%;
    left: 3%;
    width: 100%;
}

.footer-text {
    color: gray;
    font-weight: 700;
    margin-bottom: 0px !important;
}

.password_input_group {
    position: relative;
}

.password_icon {
    position: absolute;
    right: 5%;
    top: 36%;
    color: #808080;
}

.login-btn {
    border-radius: 5px;
    background: #109299;
    height: 44px;
    width: 100%;
    border: none;
    outline: none;
    color: #fff;
    text-align: center;
    font-family: 'Noto Serif Devanagari', serif;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-btn span {
    transform: translateY(4px);
}

.forgat_pass__btn_sec {
    margin: 1.5rem 0rem;
}

.forgat-btn {
    border-radius: 5px;
    background: #fff;
    height: 44px;
    width: 100%;
    border: 1px solid #11939A;
    outline: none;
    color: #11939A;
    text-align: center;
    font-family: 'Noto Serif Devanagari', serif;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgat-btn span {
    transform: translateY(4px);
}


/* profile details page */

.main_profile_details {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_profile_box {
    width: 50%;
}

.profileDetails_span {
    font-size: 14px;
}

.profile_user-login-text {
    color: #282828;
    font-family: 'Noto Serif Devanagari', serif;
    font-size: 16px;
    font-weight: 600;
    margin: 0.8rem 0rem 0rem 0rem;
}

.profile_detail_box {
    height: 50px;
    border-radius: 5px !important;
    border: 1px solid #DEE8EC !important;
    background: #F0F5F7 !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding: 0rem 3rem;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}

.label-container {
    display: flex;
    align-items: center;
}

.name_profile::after {
    content: ':';
    margin-left: 14px;
    position: absolute;
    left: 20%;
    top: 50%;
    transform: translateY(-50%);
}

.profile_details_placeholder::placeholder {
    padding-left: 6px !important;
}

.login_pass_error_group_change_password {
    position: relative;
    margin: 0.5rem 0rem 0rem 0rem;
}

.profile_label_name {
    font-family: 'Noto Serif Devanagari', serif;
}

@media (max-width:760px) {
    .main_profile_box {
        width: 100%;
    }
    .name_profile::after {
        display: none;
    }
    .profile_detail_box {
        padding: 0rem 1rem;
    }
}

@media (min-width:1000px) and (max-width:1450px) {
    .login_id_icon_sec {
        width: 8%;
    }
    .img_profile {
        width: 8% !important;
    }
    .grampanchyat_top {
        width: 60% !important;
    }
    .profile_detail_box {
        height: 45px;
    }
    .profile_details_placeholder {
        height: 40px !important;
    }
    .profile_label_name {
        font-size: 14px;
    }
}


/* end profile details css */

@media (min-width:1300px) and (max-width:1500px) {
    .main-login-card {
        width: 80%;
        padding: 0rem 1rem 1rem 1rem;
    }
    .login_left_img_map_main .loginMap {
        height: 400px !important;
    }
    .main-section {
        margin-top: 20px !important;
    }
    .login-text {
        font-size: 28px;
    }
    .login-card {
        padding: 1rem !important;
    }
    .user-login-text {
        font-size: 20px;
    }
    .login_right_logo_top {
        margin: 1rem;
    }
    .login_right_logo_top img {
        width: 50%;
    }
    .login_profile_logo_mai {
        margin-bottom: 1rem;
    }
    .login_profile_logo_mai img {
        width: 20%;
    }
    .login_right_title_top {
        font-size: 14px;
    }
    .login-input {
        height: 32px;
        font-size: 12px;
        padding: 0rem 1rem 0rem 2.5rem;
    }
    .login-input::placeholder {
        font-size: 12px;
    }
    .login_id_icon_sec .user_id_ico {
        font-size: 10px;
    }
    .login_id_icon_sec .user_pass_ico {
        font-size: 12px;
    }
    .password_icon {
        font-size: 12px;
    }
    .remember_me_sec .remember_text {
        font-size: 12px;
    }
    .login-btn,
    .forgat-btn {
        height: 32px;
        font-size: 12px;
    }
    .error_sec_login .error_text {
        font-size: 12px;
    }
    .forgat_pass__btn_sec {
        margin: 1.5rem 0rem 0rem 0rem;
    }
    .userLabel {
        font-size: 10px;
        font-weight: 500;
    }
    .login_otp_label_sec .otp_verification_title {
        font-size: 12px;
        line-height: 0rem;
    }
    .login_otp_label_sec .otp_receive_title {
        font-size: 11px;
    }
    .otp-input {
        height: 32px;
        font-size: 12px;
        padding: 0rem 1rem 0rem 2.5rem;
    }
    .otp-input::placeholder {
        font-size: 12px;
    }
    .login_otp_icon_sec .user_otp_ico {
        font-size: 12px;
    }
    .send_otp_sec {
        margin: 0.8rem 0rem 1rem 0rem;
    }
    .login_otp_group {
        margin: 0rem !important;
    }
    .opt_inp_sec {
        margin: 0.5rem 0rem 0.8rem 0rem;
    }
}

@media (max-width:1000px) {
    .login_left_img_col {
        display: none;
    }
    .login_right_main {
        justify-content: center;
    }
    /* .login_left_img_map_main .loginMap {
        height: 200px !important;
    } */
    .login-card {
        width: 100% !important;
        margin-bottom: 20px !important;
    }
    .login_right_logo_top img {
        width: 65%;
    }
    .login_profile_logo_mai img {
        width: 18%;
    }
    .user-login-text {
        font-size: 24px;
    }
    .login_right_title_top {
        font-size: 16px;
    }
    .login-input {
        height: 36px;
        font-size: 13px;
        padding: 0rem 1rem 0rem 3rem;
    }
    .login-input::placeholder {
        font-size: 13px;
    }
    .login_id_icon_sec .user_id_ico {
        font-size: 11px;
    }
    .login_id_icon_sec .user_pass_ico {
        font-size: 13px;
    }
    .password_icon {
        font-size: 13px;
    }
    .remember_me_sec .remember_text {
        font-size: 13px;
    }
    .login-btn,
    .forgat-btn {
        height: 36px;
        font-size: 14px;
    }
    .userLabel {
        font-size: 11px;
    }
    .login_otp_label_sec .otp_verification_title {
        font-size: 14px;
    }
    .login_otp_label_sec .otp_receive_title {
        font-size: 12px;
    }
    .otp-input {
        height: 36px;
        font-size: 13px;
        padding: 0rem 1rem 0rem 3rem;
    }
    .otp-input::placeholder {
        font-size: 13px;
    }
    .login_otp_icon_sec .user_otp_ico {
        font-size: 13px;
    }
}