.model_main_section {
    margin-bottom: 8rem;
}

.top_row {
    padding: 0.5rem !important;
    margin: 0.1rem !important;
    margin-bottom: 1rem !important;
    background-color: #ffff;
}

.top_col_lg_3 {
    width: 20% !important;
    padding-right: 6px !important;
    padding-left: 6px !important;
}

.vasuli_vibhag_top_main_card {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/deputyco/top_img-1.png);
    /* background-position: center; */
    object-fit: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    padding: 1rem;
}

.vasuli_vibhag_top_main_card_2 {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/deputyco/top_img_2.png);
    /* background-position: center; */
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    padding: 1rem;
}

.vasuli_vibhag_top_main_card_3 {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/deputyco/top_img_3.png);
    /* background-position: center; */
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    padding: 1rem;
}

.vasuli_vibhag_top_main_card_4 {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/deputyco/top_img-4.png);
    /* background-position: center; */
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    padding: 1rem;
}

.vasuli_vibhag_top_main_card_5 {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/deputyco/top_img--5.png);
    /* background-position: center; */
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    padding: 1rem;
}

.name_of_model_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.name_of_model_top .name_of_model_top_h4 {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0px !important;
}

.vasuli_vibhag_top_card_bottom_sec {
    display: flex;
    justify-content: space-between;
    margin-top: .5rem;
}

.bottom_sec_first {
    border-right: 1px solid #fff;
    width: 50%;
}

.bottom_sec_first_sub_1 {
    margin-bottom: 1rem;
}

.bottom_sec_first_sub_1 .h2,
.bottom_sec_first_sub_2 .h2 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 0px !important;
}

.bottom_sec_first_sub_1 .span,
.bottom_sec_first_sub_2 .span {
    font-size: 13px !important;
    color: #fff;
}

.bottom_sec_second {
    width: 50%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.bottom_sec_second_sub_1 {
    height: 50%;
}

.bottom_sec_second_sub_1 .h2 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 0px !important;
}

.bottom_sec_second_sub_1 {
    font-size: 13px !important;
    color: #fff;
}

.bottom_sec_second_sub_2 {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: end;
}

.top_cards_models {
    width: 20% !important;
    margin-top: 1rem !important;
    padding: 0 6px !important;
}

.expense_bottom_sec_first {
    border-bottom: 1px solid #fff;
    width: 100%;
    padding: 0.4rem 0rem;
}

.expense_bottom_sec_second {
    text-align: end;
    padding: 0.4rem 0rem;
}

.expense_bottom_sec_first .h2,
.expense_bottom_sec_second .h2 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 0px !important;
}

.expense_bottom_sec_first .span,
.expense_bottom_sec_second .span {
    font-size: 13px !important;
    color: #fff;
}

.panchayat_main_card {
    height: 120px;
    width: 100%;
    background-image: url(../../assets/images/deputyco/orange-bg-top.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
}

.grampanchayat_main_card {
    height: 120px;
    width: 100%;
    background-image: url(../../assets/images/deputyco/orange-bg-top.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
}

.gramsevek_main_card {
    height: 120px;
    width: 100%;
    background-image: url(../../assets/images/deputyco/orange-bg-top.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
}

.vistar_adhikari_main_card {
    height: 120px;
    width: 100%;
    background-image: url(../../assets/images/deputyco/orange-bg-top.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
}

.gatvikas_main_card {
    height: 120px;
    width: 100%;
    background-image: url(../../assets/images/deputyco/orange-bg-top.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
}

.name_of_model {
    position: absolute;
    top: 7%;
    left: 8%;
    display: flex;
    width: 88%;
    justify-content: space-between;
    align-items: center;
}

.name_of_model .n_o_m_h4 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 0px !important;
}

.total_count_of_model {
    position: absolute;
    bottom: 7%;
    right: 7%;
}

.total_count_of_model .t_o_m_h4 {
    color: #fff;
    font-size: 32px;
    margin-bottom: 0px !important;
}

.model_report_card {
    border: none !important;
    border-radius: 0px !important;
    background-color: #fff !important;
}

.model_notice_board_card {
    border: none !important;
    border-radius: 0px !important;
    background-color: #fff !important;
    height: 100% !important;
}

.model_report_cart_title {
    margin: 1rem;
}

.model_report_cart_title .m_r_c_t_span {
    font-weight: 600;
    font-size: 19px;
}

.notice_board_title {
    font-weight: 600;
    font-size: 19px;
}


/* खर्च तरतूद css  */

.expense_row {
    padding: 0rem !important;
    margin: 0.1rem !important;
    margin-bottom: 0.5rem !important;
}

.expense_main_col_4 {
    padding: 0px !important;
    width: 31% !important;
    padding-left: 6px !important;
}

.expense_main_sec {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/deputyco/expense_blue_bg.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    padding: 1rem 1.8rem;
}

.expense_main_sec_2 {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/deputyco/expense_orange_bg.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    padding: 1rem 1.8rem;
}

.expense_main_sec_3 {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/deputyco/expense_red_bg.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    padding: 1rem 1.8rem;
}

.expense_main_sec_4 {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/deputyco/expense_voilet_bg.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    padding: 1rem 1.8rem;
}

.expense_main_sec_5 {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/grambox/Group49.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    padding: 1rem 1.8rem;
}

.name_of_model_expense_blue {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name_of_model_expense_orange {
    display: flex;
    justify-content: space-between;
}

.name_of_model_expense_orange .n_m_e_o_h4 {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0px !important;
}

.name_of_model_expense_blue .n_m_e_b_h4 {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0px !important;
}

.name_of_model_expense_blue .n_m_e_b_span svg {
    color: #fff !important;
    font-size: 20px !important;
}

.first_count_sec_ex {
    width: 50%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 1rem;
    border-right: 1px solid #dddddd4f;
}

.second_count_sec_ex {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.expense_main_col_2 {
    width: 22.8% !important;
    padding: 0px!important;
}

.expense_counting_sec_main {
    display: flex;
    justify-content: space-between;
    margin-top: .5rem;
}

.count_sec_ex .count_sec_h4 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 0px !important;
}

.count_sec_ex .count_sec_span {
    font-size: 13px !important;
    color: #fff;
}

.expense_last_one_common_count_sec {
    display: flex;
    margin-top: .5rem;
}

.mg_count_first_sec {
    border-right: 1px solid #dddddd4f;
    width: 39%;
}

.mg_count_first_sec .m_c_f_s_h4 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 0px !important;
}

.mg_count_first_sec .m_c_f_s_span {
    font-size: 13px !important;
    color: #fff;
}

.mg_count_second_sec {
    text-align: center;
    width: 39%;
}

.mg_count_second_sec .m_c_s_s_h4 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 0px !important;
}

.mg_count_second_sec .m_c_s_s_span {
    font-size: 13px !important;
    color: #fff;
}

.arrow_right {
    display: flex;
    justify-content: end;
    cursor: pointer;
}


/* खर्च तपशील  css*/

.name_of_model_expense_red {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.expense_tapshil_count_sec {
    border-top: 1px solid #dddddd4f;
}

.expense_tapshil_count_sec {
    border-top: 1px solid #dddddd4f;
}

.expense_of_total_count_sec .e_o_t_c_s_h4 {
    color: #fff;
    font-size: 16px;
    margin-bottom: 1rem !important;
}

.e_o_t_c_s_h3 {
    font-size: 20px;
    color: #fff;
    position: relative;
    display: inline-block;
    margin-top: 1.5rem;
    margin-bottom: 0px !important;
}

.e_o_t_c_s_h3::after {
    position: absolute;
    content: "";
    width: 72%;
    height: 1px;
    background-color: #fbfbfb54;
    top: -16px;
    left: 0;
}

.expense_bottom_sec {
    display: flex;
    flex-direction: column-reverse;
}

.e_o_t_c_s_span {
    font-size: 13px !important;
    color: #fff;
}


/* खर्च तपशील  css*/


/* १५ वित्य आयोग मासिक प्रगती अहवाल css */

.fifteen_vith_banner_sec {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/deputyco/expense_blue_bg.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    padding: .3rem 1.8rem 0rem 1.8rem;
}

.name_of_model_expense_15_vith {
    display: flex;
    justify-content: end;
}

.name_of_model_expense_15_vith .n_m_e_b_span {
    color: #fff !important;
    font-size: 20px !important;
}

.fifteen_vith_left_main {
    width: 50%;
    border-right: 1px solid #dddddd4f;
}

.fifteen_vith_right_main {
    width: 50%;
    /* border-right: 1px solid rgba(255, 255, 255, 0.70); */
}

.fifteen_vith_left_one_sec {
    display: flex;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid #dddddd4f;
    padding: 0rem 0rem 0.4rem 0rem;
}

.fifteen_vith_left_second_sec {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.4rem 0rem 0rem 0rem;
}

.fifteen_vith_right_middle_sec {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 100%;
}

.fifteen_vith_arrow_top {
    display: flex;
    justify-content: end;
    /* margin-top: -1rem; */
}

.fifteen_vith_arrow_top .f_v_a_t_span {
    color: #fff !important;
    font-size: 16px !important;
    margin-top: -.8rem;
}

.fifteen_vith_arrow_top .n_m_e_b_span {
    color: #fff !important;
    font-size: 16px !important;
}

.fifteen_vith_arrow_top .n_m_e_b_span svg {
    color: #fff !important;
    font-size: 20px !important;
}


/* १५ वित्य आयोग मासिक प्रगती अहवाल css */


/* pravin css of vasuli vibhag section */

.vasuli_vibhag_banner_sec {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/deputyco/expense_blue_bg.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    padding: 1rem 1.8rem 0rem 1.8rem;
}

.gramsevek_main_card_recovery {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/grambox/Group55.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    padding: 13px;
}

.gramsevek_main_card_gharkul {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/grambox/Group49.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    /* padding: 23px 12px 40px 0px  */
    padding: .7rem 1rem 1.3rem 1rem;
}

.gramsevek_main_card_gharkul_water {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/grambox/Group54.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    /* padding: 23px 12px 40px 0px  */
    padding: .7rem 1rem 1.3rem 1rem;
}

.gramsevek_main_card_gharkul_Kirkol {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/grambox/Group51.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    /* padding: 23px 12px 40px 0px  */
    padding: .7rem 1rem 1.3rem 1rem;
}

.gramsevek_main_card_gharkul_pavanchakki {
    height: 100%;
    width: 100%;
    background-image: url(../../assets/images/grambox/Group52.png);
    background-position: center;
    object-fit: cover;
    background-size: 100% 100%;
    position: relative;
    /* padding: 23px 12px 40px 0px  */
    padding: .7rem 1rem 1.3rem 1rem;
}

.recovery_box {
    border-right: 1px solid #dddddd4f;
    width: 50%;
}

.recovery_box1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.recovery_mainbox {
    display: flex;
    /* gap: 5%;
    justify-content: center; */
}

.recovery_width {
    width: 17% !important;
    padding: 0px !important;
}

.recovery_width1 {
    width: 21.7% !important;
    padding: 0px !important;
}

.recovery_width1_2 {
    width: 29.4% !important;
    padding: 0px !important;
}

.name_of_model1 {
    /* position: absolute;
    top: 7%;
    left: 8%;
    padding-right: 10px; */
    padding: 10px 13px 7px 8px;
    border-bottom: 1px solid #dddddd4f;
}

.name_of_model2 {
    padding-top: 8px;
    padding-left: 6px;
}

.text_recovery {
    color: white;
    font-size: 22px;
}

.text_recovery1 {
    font-size: 16px;
    color: white;
}

.percentage_recovery {
    position: relative;
    bottom: 0px;
    top: 28%;
}

.vasuli_total {
    font-size: 13px;
}

.icon_arrow {
    font-size: 15px;
    color: white;
}

.progress_bar {
    margin-top: 7px;
}

.ghrakul_box {
    padding-left: 17px;
    padding-top: 23px;
}

.ghrakul_second {
    margin-top: 47px;
}

.ghrakul_second1 {
    margin-top: 67px !important;
}

.garpti_end_section {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 5px;
}

.garpti_end_section .g_e_s_h4 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 0px !important;
}

.recovery_main_second_subsection {
    display: flex;
    justify-content: space-between;
}

.first_total_recovery {
    width: 50%;
    border-right: 1px solid #dddddd4f;
    padding-left: 10px;
}

.count_of_recovery .c_o_r_h4 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 0px !important;
}

.count_of_recovery .c_o_r_span {
    font-size: 13px;
    color: #fff;
}

.count_of_total_magni .c_o_t_m_h4 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 0px !important;
}

.count_of_total_magni1 {
    padding-top: 23px;
}

.count_of_total_magni1 .c_o_t_m_h4 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 0px !important;
}

.count_of_total_magni .c_o_t_m_span {
    font-size: 13px;
    color: #fff;
}

.count_of_total_magni1 .c_o_t_m_span {
    font-size: 13px;
    color: #fff;
}

.gharpatti_total_percent {
    width: 50%;
    display: flex;
    flex-direction: column-reverse;
    align-items: end;
}

.gharpatti_total_percent_ghrakul {
    display: flex;
    flex-direction: column-reverse;
    align-items: end;
}

.gharpatti_total_percent1 {
    width: 17% !important;
    display: flex;
    flex-direction: column-reverse;
    align-items: end;
}

.gharpatti_sub_sec_t_p .g_s_s_t_p_h4 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 0px !important;
}

.gharpatti_sub_sec_t_p .g_s_s_t_p_span {
    font-size: 13px;
    color: #fff;
}

.count_of_recovery {
    padding-bottom: 10px;
}

.count_of_recovery1 {
    border-bottom: 1px solid #dddddd4f;
    color: white;
    font-size: 16px;
    padding-bottom: 25px;
}

.first_total_recovery1 {
    padding-left: 10px;
    width: 100% !important;
}


/* notice board css */

.notice_board_title_main_group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.notice_board_title_sub_left_group {
    display: flex;
    gap: .8rem;
    align-items: center;
}

.notice_information_list {
    padding: 0rem 4.5rem;
}

.notice_information_ul_list {
    padding: 0rem !important;
}

.notice_information_ul_list li {
    list-style: none;
    font-size: 16px;
    margin-bottom: 1rem;
}

.view_more_notice_list {
    display: flex;
    justify-content: end;
    padding: 0rem 1rem 1rem 0rem;
}


/* notice board css */

@media (min-width:1300px) and (max-width:1500px) {
    .panchayat_main_card,
    .grampanchayat_main_card,
    .gramsevek_main_card,
    .vistar_adhikari_main_card,
    .gatvikas_main_card {
        height: 85px;
    }
    .model_report_cart_title .m_r_c_t_span {
        font-size: 16px;
    }
    .name_of_model h4 {
        font-size: 18px;
    }
    .total_count_of_model h4 {
        font-size: 26px;
    }
    .model_report_cart_title span {
        font-size: 16px;
    }
    .deputy_co_table_thead tr th {
        font-size: 13px !important;
    }
    .deputy_co_table_tbody tr td {
        font-size: 12px !important;
    }
    .vasuli_vibhag_top_main_card,
    .vasuli_vibhag_top_main_card_2,
    .vasuli_vibhag_top_main_card_3,
    .vasuli_vibhag_top_main_card_4,
    .vasuli_vibhag_top_main_card_5 {
        padding: .5rem;
    }
    .name_of_model_top .name_of_model_top_h4 {
        font-size: 14px;
    }
    .bottom_sec_first_sub_1 .h2,
    .bottom_sec_first_sub_2 .h2 {
        font-size: 20px;
    }
    .bottom_sec_first_sub_1 .span,
    .bottom_sec_first_sub_2 .span {
        font-size: 12px !important;
    }
    .bottom_sec_second_sub_1 .h2 {
        font-size: 20px;
    }
    .name_of_model_top {
        margin-bottom: 2px;
    }
    .expense_bottom_sec_first .h2,
    .expense_bottom_sec_second .h2 {
        font-size: 20px;
    }
    .bottom_sec_second_sub_1 {
        font-size: 12px !important;
    }
    .expense_bottom_sec_first .span,
    .expense_bottom_sec_second .span {
        font-size: 12px !important;
    }
    .name_of_model .n_o_m_h4 {
        font-size: 14px;
    }
    .total_count_of_model .t_o_m_h4 {
        font-size: 20px;
    }
    .expense_main_sec,
    .expense_main_sec_2,
    .expense_main_sec_3,
    .expense_main_sec_4,
    .expense_main_sec_5 {
        padding: 1rem;
    }
    .name_of_model_expense_blue .n_m_e_b_h4,
    .name_of_model_expense_orange .n_m_e_o_h4 {
        font-size: 12px;
    }
    .count_sec_ex .count_sec_h4,
    .mg_count_first_sec .m_c_f_s_h4,
    .mg_count_second_sec .m_c_s_s_h4 {
        font-size: 20px;
    }
    .count_sec_ex .count_sec_span,
    .mg_count_first_sec .m_c_f_s_span,
    .mg_count_second_sec .m_c_s_s_span {
        font-size: 12px !important;
    }
    .first_count_sec_ex,
    .second_count_sec_ex {
        gap: .5rem;
    }
    .expense_of_total_count_sec .e_o_t_c_s_h4 {
        font-size: 12px;
    }
    /* pravin */
    .count_of_recovery .c_o_r_h4 {
        font-size: 15px;
        color: #fff;
    }
    .count_of_recovery1 .c_o_r_h4 {
        font-size: 15px;
        color: #fff;
    }
    .count_of_recovery .c_o_r_span {
        font-size: 13px;
    }
    .count_of_total_magni .c_o_t_m_h4 {
        font-size: 15px;
    }
    .count_of_total_magni1 .c_o_t_m_h4 {
        font-size: 15px;
    }
    .count_of_total_magni .c_o_t_m_span {
        font-size: 13px;
    }
    .count_of_total_magni1 .c_o_t_m_span {
        font-size: 13px;
    }
    .garpti_end_section .g_e_s_h4 {
        font-size: 13px;
    }
    .gharpatti_sub_sec_t_p .g_s_s_t_p_h4 {
        font-size: 15px;
    }
    .gharpatti_sub_sec_t_p .g_s_s_t_p_span {
        font-size: 13px;
    }
    .gramsevek_main_card_gharkul {
        padding: 14px 7px;
        height: 152px;
    }
    .gramsevek_main_card_gharkul_water {
        padding: 14px 7px;
        height: 152px;
    }
    .gramsevek_main_card_gharkul_Kirkol {
        padding: 14px 7px;
        height: 152px;
    }
    .gramsevek_main_card_gharkul_pavanchakki {
        padding: 14px 7px;
        height: 152px;
    }
    .gramsevek_main_card_recovery {
        height: 150px;
    }
    .gharpatti_total_percent {
        padding-right: 10px;
    }
    .gharpatti_total_percent1 {
        padding-right: 10px;
    }
    .name_of_model1 {
        padding: 10px 13px 0px 8px;
    }
    .percentage_recovery {
        top: 18%;
    }
    .fifteen_vith_arrow_top .f_v_a_t_span {
        font-size: 13px !important;
        margin-top: -.6rem;
    }
    .e_o_t_c_s_span {
        font-size: 12px !important;
    }
    .fifteen_vith_right_middle_sec {
        gap: .5rem;
    }
    .notice_board_title {
        font-size: 16px;
    }
    .notice_information_ul_list li {
        font-size: 14px;
    }
}

@media (min-width:700px) and (max-width:1200px) {
    .top_col_lg_3 {
        width: 50% !important;
        margin-bottom: 1rem;
    }
    .top_cards_models {
        width: 50% !important;
    }
    .panchayat_main_card,
    .grampanchayat_main_card,
    .gramsevek_main_card,
    .vistar_adhikari_main_card,
    .gatvikas_main_card {
        height: 90px;
    }
    .expense_main_col_4 {
        width: 50% !important;
    }
    .expense_main_col_2 {
        width: 50% !important;
    }
}

@media (max-width:700px) {
    .top_col_lg_3 {
        width: 100% !important;
        margin-bottom: 1rem;
    }
    .top_cards_models {
        width: 50% !important;
    }
    .panchayat_main_card,
    .grampanchayat_main_card,
    .gramsevek_main_card,
    .vistar_adhikari_main_card,
    .gatvikas_main_card {
        height: 90px;
    }
    .expense_main_col_4 {
        width: 100% !important;
    }
    .expense_main_col_2 {
        width: 100% !important;
    }
    .recovery_width {
        width: 100% !important;
    }
    .recovery_width1 {
        width: 100% !important;
    }
    .recovery_width1_2 {
        width: 100% !important;
    }
    .vasuli_vibhag_banner_sec,
    .fifteen_vith_banner_sec {
        padding: 1rem;
    }
}