.village-input-box {
    border: none;
}

.table-input-box {
    height: 30px;
    margin: 0 !important;
    border-radius: 0 !important;
    border: none !important;
}

.table-input-box:focus {
    border: none !important;
    border-radius: 0 !important;
    /* box-shadow: none !important; */
}

.table_delete_btn1 {
    border: none;
    color: blue;
    background-color: white !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.excel_upload_to_api {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    padding: 3rem;
}


/* .download_btn_sample_sec {
    display: flex;
    justify-content: end;
} */