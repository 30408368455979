.village-input-box {
    border: none;
}

.table-input-box {
    height: 30px;
    margin: 0 !important;
    border-radius: 0 !important;
    border: none !important;
    background-color: transparent !important;
}

.table-input-box:focus {
    border: none !important;
    border-radius: 0 !important;
    /* box-shadow: none !important; */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.width_grma{
  width: 20vh !important;
}
.arrow-box-active{
    border-bottom: 3px solid #1976d2;
    top: 45px;
    margin-bottom: -19px;
}