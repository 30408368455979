.village-input-box {
    border: none;
}

.table-input-box {
    height: 30px;
    margin: 0 !important;
    border-radius: 0 !important;
    border: none !important;
}

.table-input-box:focus {
    border: none !important;
    border-radius: 0 !important;
    /* box-shadow: none !important; */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.navigate-section-header {
    background: rgb(255, 255, 255);
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
    height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrow-icon {
    margin-bottom: -38px !important;
}

.arrow-icon svg {
    width: 1.8em !important;
    height: 1.8em !important;
}

.arrow-box-active {
    border-bottom: 3px solid #1976d2;
    top: 45px;
    margin-bottom: -19px;
}