.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* bottom: 0;
    width: 100%; */
    padding: .5rem 5rem;
    /* position: relative;
    margin-left: 0; */
    /* position: fixed;
    left: 0;
    bottom: 0;
    right: 0; */
    margin-top: auto
}

.footer_bg {
    background: #282828;
}

.footer_project_dev_info_text {
    color: #FFF;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0px !important;
}

.footer_right_side_text {
    color: #FFF;
    font-family: 'Noto Serif Devanagari', serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 0px !important;
}

@media (min-width:1300px) and (max-width:1500px) {
    .footer_right_side_text {
        font-size: 14px;
    }
    .footer_project_dev_info_text {
        font-size: 11px;
    }
}

@media (max-width:1000px) {
    .footer {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        padding: 1rem;
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        margin-top: auto;
    }
    .footer_right_side_text {
        font-size: 15px;
    }
}